<template>
  <div>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        
          <v-select
            v-model="course_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            :reduce="course => course.id"
            placeholder="Seleccionar Curso"
          />
          
        </b-form-group>  
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Search"
            label-for="mc-first-name"
          >
              <b-form-input
                v-model="search"
                id="mc-first-name"
                placeholder="Search by name and email"
                name="search"
              />
          </b-form-group>
          
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mt-2"
        >
          <b-button    
            variant="primary"
            class="float-right"
            @click="filterListing"
          >
            Search
      </b-button>
          
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mt-2"
        >
          <b-button    
            variant="primary"
            class="float-left"
            @click="resetListing"
          >
            Reset
      </b-button>
          
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <b-card-code
    title="Alumnos"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        v-if="this.$store.getters.checkPermission('create-student')"
        to="/create-student"
      >
            Crear Alumno
      </b-button>
    </b-col>
    <b-table
      :fields="fields"
      :items="studentLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>

      <template #cell(id)="data">
        <div class="text-nowrap">
            <b-button v-if = "data.item.is_profile && checkEditPermission" variant="outline-primary" :to="'/student/'+data.value+'/edit'" size="sm"><b-icon icon="pencil-square"></b-icon></b-button>
            <b-button class="ml-1" v-if="checkDeletePermission" @click="deleteStudent(data.value)" variant="outline-danger" size="sm"><b-icon icon="archive-fill"></b-icon></b-button>
            <b-button class="ml-1" variant="outline-info" :to="'/student/'+data.value+'/detail'" size="sm"><b-icon icon="eye-fill"></b-icon></b-button>
            <b-button v-if="data.item.user_detail && data.item.user_detail.course_id" class="ml-1" variant="outline-primary" @click="unenrollStudent(data.value)" size="sm">Un enroll Student</b-button>
        </div>      
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BFormInput, BCardHeader,BFormGroup, BCardBody, BCard, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import StudentService from '@/services/student.service.js';
import vSelect from 'vue-select'
import CourseService from '@/services/course.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from '@/services/user.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCard,
    BFormGroup,
    vSelect
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'name', label: 'Username' },
        // A regular column
        { key: 'email', label: 'Email' },
        // A regular column
        { key: 'created_at', label: 'Created At' },
        { key: 'id', label: 'Action' },
      ],
      courses: [],
      course_id: '',
      search: '',
      studentLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
      checkDeletePermission: this.$store.getters.checkPermission('delete-student'),
      checkEditPermission: this.$store.getters.checkPermission('student-edit')
    }
  },
  methods: {
    getStudents() {
      this.showOverlay = true;
      StudentService.listing(
        this.currentPage,
        this.course_id,
        this.search
        ).then(response => {
          this.studentLists = response.data.data.students.data;
          this.rows = response.data.data.students.total;
          this.currentPage = response.data.data.students.current_page
          this.path = response.data.data.students.path
          this.pageCount = response.data.data.students.last_page;
          this.currentPage = response.data.data.students.current_page;
          this.perPageRecord = response.data.data.students.per_page;
          this.toRecordCount = response.data.data.students.to;
          this.fromRecordCount = response.data.data.students.from;
          this.totalRecordCount = response.data.data.students.total;
          this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
          this.showOverlay = false;
        }).catch(error => {
          this.showOverlay = false;
          console.log(error);
        });
    },
    unenrollStudent(data)
    {
      UserService.unenrollStudent(data).then(response => {
        this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Student Un Enroll Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
      }).catch(error => {
        console.log(error)
      });
    },
    resetListing() {
      this.search = '';
      this.course_id = '';
      this.currentPage = 1
      this.getStudents();
    },
    filterListing() {
      this.currentPage = 1
      this.getStudents();
    },
    changePage(event) {
      this.getStudents(this.currentPage);
    },
    getCourses()
    {
      CourseService.getAll().then(response => {
        this.courses = response.data.data.courses;  
      }).catch(error => {
        console.log(error)
      });
    },
    deleteStudent(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          UserService.delete(id).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'User has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  
                  const userIndex = this.studentLists.findIndex(user => user.id === id);
                  this.studentLists.splice(userIndex, 1);   
                  this.showOverlay = false;
                    
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    }
  },
  created() {
    this.getStudents(this.currentPage);
    this.getCourses();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
