import axiosIns from '@/libs/axios.js';

class StudentService {
    listing(pageNumber=1, course = null, search = null){
        return axiosIns.get('/student/listing?page='+pageNumber+'&course='+course+'&search='+search).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    edit(id, data)
    {
        return axiosIns.post(`/update-student-profile/${id}`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    getAll()
    {
        return axiosIns.get('/all-student/listing').then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new StudentService();